/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import RefreshIcon from '@heroicons/react/solid/RefreshIcon';
import SearchIcon from '@heroicons/react/solid/SearchIcon';
import './index.tailwind.css';
import { useImmer } from 'use-immer';
import useSWR from 'swr';
import Modal from './Modal';
import { StickyContainer, Sticky } from 'react-sticky';

const SearchBox = (props: any) => {
  let { loading, search, onSearch } = props;
  let [value, setValue] = useState(search);

  useEffect(() => {
    setValue(search);
  }, [search]);

  return <div className="
        flex items-center rounded-full shadow-xl
      bg-white 
      dark:bg-gray-700 
      ">
    <input className="rounded-l-full w-full py-4 px-6 
      text-gray-700 
      dark:bg-gray-700 dark:text-white dark:placeholder-white 
      leading-tight focus:outline-none
    " id="search" type="text" placeholder="Buscar ID..."
      autoComplete="off"
      onChange={value => setValue(value.target.value)}
      onKeyDown={(e) => e.keyCode === 13 && onSearch(value)}
      value={value}
    />
    <div className="p-4">
      <button className="
      bg-blue-500 
      text-white rounded-full p-2 hover:bg-blue-400 
      focus:outline-none w-12 h-12 flex items-center justify-center"
      onClick={() => onSearch(value)}
      >
        {loading && <RefreshIcon className="h-6 w-6 text-white animate-spin-rev" /> }
        {!loading && <SearchIcon className="h-6 w-6 text-white" /> }
      </button>
      </div>
    </div>
}

const ResultBox = (props: any) => {
  let { results, onSearch } = props;
  return <div id="resultbox" className=" fixed
      w-10/12
      mt-12
      rounded-3xl shadow-xl
      overflow-auto
      dark:text-white
      bg-white 
      dark:bg-gray-700 
      "

      style={{
        maxHeight: '50vh'
      }}
      >

        {!!results.cliente?.length && 
          <h5 className="sticky top-0 px-4 pt-2 font-bold text-sm bg-white dark:bg-gray-700 ">Clientes</h5>
        }
        {results.cliente.map((el: any) => 
            <div className="p-4 hover:bg-opacity-10 hover:bg-black cursor-pointer">
              <h3 className="font-bold">
                {el.cuenta_nombre} ({el.cuenta_id})
              </h3>
              <h5 className="text-xs">
                {el.cuenta_fecha_borrado && <>
                    <b> Fecha baja:&nbsp; </b>
                    <span> {el.cuenta_fecha_borrado} </span>
                  </>
                }
                {!el.cuenta_fecha_borrado && <>
                    <b> Cuenta activa </b>
                  </>
                }
              </h5>
            </div>
        )}
        {!!results.accion?.length && 
          <h5 className="sticky top-0 px-4 pt-2 font-bold text-sm bg-white dark:bg-gray-700 ">Campañas</h5>
        }
        {results.accion.map((el: any) => 
            <div className="p-4 hover:bg-opacity-10 hover:bg-black cursor-pointer">
              <h3 className="font-bold">
                <span className="underline" onClick={() => onSearch(el.camp_id)}>
                  {el.camp_nom} ({el.camp_id})
                </span>
              </h3>
              <h4 className="text-sm font-bold">
                Fecha:&nbsp;{el.elem_fecha_creacion.slice(0, 10)}
              </h4>
              <h5 className="text-xs">
                Cuenta:&nbsp;
                <span className="underline" onClick={() => onSearch(el.cuenta_id)}>
                  {el.cuenta_nombre} ({el.cuenta_id})
                </span>
                &nbsp;-&nbsp;
                Contrato:&nbsp;
                {el.contrato_nom} ({el.contrato})
              </h5>
            </div>
        )}
        {!!results.elemento?.length && 
          <h5 className="sticky top-0 px-4 pt-2 font-bold text-sm bg-white dark:bg-gray-700 ">Envios</h5>
        }
        {results.elemento.map((el: any) => 
            <div className="p-4 hover:bg-opacity-10 hover:bg-black cursor-pointer">
              <h3 className="font-bold">
                <span className="underline" onClick={() => onSearch(el.elem_id)}>
                  {el.elem_nombre} ({el.elem_id})
                </span>
              </h3>
              <h4 className="text-sm font-bold">
                Fecha:&nbsp;{el.elem_fecha_creacion.slice(0, 10)}
              </h4>
              <h5 className="text-xs">
                Campaña:&nbsp;
                <span className="underline" onClick={() => onSearch(el.camp_id)}>
                  {el.camp_nom} ({el.camp_id})
                </span>
                &nbsp;-&nbsp;
                Cuenta:&nbsp;
                <span className="underline" onClick={() => onSearch(el.cuenta_id)}>
                  {el.cuenta_nombre} ({el.cuenta_id})
                </span>
                &nbsp;-&nbsp;
                Contrato:&nbsp;
                {el.contrato_nom} ({el.contrato})
              </h5>
            </div>
        )}
    </div>
}



let apiUrl = "https://spotlight-api.embluemail.com"
// let apiUrl = "http://localhost:3000"
let fetcher = async (resource:any, search: any) => {
  return fetch(apiUrl + resource + '?objectId=' + search, {...search, credentials: 'include'}).then(res => res.json());
}
let fetcherInMemory = async (resource:any, search: any) => {
  console.log({ resource, search })
  return new Promise<any>((resolve) => {
    setTimeout(() => {
      if (search === ''){
        resolve(null);
        return;
      }
      resolve({
        elemento: [{
          id: '123',
          name: 'elemento test',
        }],
        accion: [{
          id: '123',
          name: 'accion test',
        }],
        cliente: [{
          id: '123',
          name: 'cliente test',
        }]
      });
    }, 1000);
  })
}

function App() {
  let [state, setState] = useImmer({
    loading: false,
    query: '',
  });
  let { loading, query } = state;
  let { data: results, isValidating, ...rest } = useSWR(['/api/search', query], fetcher);
  useEffect(() => {
    if (results && !isValidating) {
      setState(s => ({ ...s, loading: false }))
    }
  }, [setState, results, isValidating]);
  return (
    <div className="flex h-screen
    bg-gradient-to-br from-blue-100  to-blue-500 dark:from-blue-900 dark:to-black
    ">
      <div className="m-auto w-10/12">
        <div style={{ marginTop: '-33vh'}}>
          <SearchBox 
            loading={loading} 
            search={query}
            onSearch={(query: string) => {
              setState(s => ({...s, loading: query.length > 0, query }));
            }}
          />
          {results && results.elemento &&
            <ResultBox results={results} 
              onSearch={(query: string) => {
                setState(s => ({...s, loading: query.length > 0, query }));
              }}
            />
          }
        </div>
      </div>
    </div>
  );
}

export default App;
